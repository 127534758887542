import 'react-native-vector-icons/dist/MaterialIcons'
import MaterialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf'

const icons = {
  MaterialIcons,
}

const fonts = Object.keys(icons)
  .map(name => {
    const font = icons[name]
    return `@font-face { src: url('${font}'); font-family: '${name}'; format('truetype'); }`
  })
  .join('\n')

const style = document.createElement('style')
style.type = 'text/css'
if (style.styleSheet) {
  style.styleSheet.cssText = fonts
} else {
  style.appendChild(document.createTextNode(fonts))
}

document.head.appendChild(style)
