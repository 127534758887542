import { APP_NAME } from '@trendy/common/src/settings'
import { Helmet } from 'react-helmet'
import { site_name, image } from './index'

const description = 'Контакты, адрес, обратная связь, реквизиты компании ✔'
const keywords = [
  'мама и детки контакты',
  'mama&detki контакты',
  'мама и детки адрес',
  'mama&detki адрес',
].join(', ')

const title = `Контакты - маркетплейс ${APP_NAME}`

export default function ContactsHelmet() {
  return (
    <Helmet>
      <title>Контакты - {APP_NAME}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='robots' content='all' />
      <meta property='og:site_name' content={site_name} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:image' content={image} />
      <meta property='og:description' content={description} />
      <link
        rel='canonical'
        href={window.location.origin + window.location.pathname}
      />
    </Helmet>
  )
}
