import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPluginReactNavigation from '@bugsnag/plugin-react-navigation'

Bugsnag.start({
  apiKey: 'c9a4e8cfb90a594d65bf18487f380d6a',
  enabledReleaseStages: ['production'],
  plugins: [new BugsnagPluginReact(), new BugsnagPluginReactNavigation()],
})

export default Bugsnag
