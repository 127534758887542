import logo from '@trendy/atoms/src/assets/mama&detki-logo.png'
import { APP_NAME, SERVER_URL } from '@trendy/common/src/settings'
import React from 'react'
import { Helmet } from 'react-helmet'
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
  useParams,
} from 'react-router-dom'
import linking from '../linking'
import * as categories from './categories.json'
import BasketHelmet from './BasketHelmet'
import ContactsHelmet from './ContactsHelmet'
import DeliveryHelmet from './DeliveryHelmet'
import ExchangeHelmet from './ExchangeHelmet'
import FavoriteHelmet from './FavoriteHelmet'
import GuaranteeHelmet from './GuaranteeHelmet'
import MainHelmet from './MainHelmet'
import PartnershipHelmet from './PartnershipHelmet'
import PrivacyPolicyHelmet from './PrivacyPolicyHelmet'
import ProductHelmet from './ProductHelmet'
import ProfileHelmet from './ProfileHelmet'
import QuestionsHelmet from './QuestionsHelmet'
import TermsOfUseHelmet from './TermsOfUseHelmet'

export const site_name = `Маркетплейс детских товаров ${APP_NAME} в Бишкеке`

export const image = `${SERVER_URL}${logo}`

const { Categories } = linking.config.screens.Main.screens

function CategoriesHelmet() {
  const [searchParams] = useSearchParams()
  const params = useParams()
  const category_id = params.id || searchParams.get('category')
  const category = categories[category_id]
  return category ? (
    <Helmet>
      <title>
        {category.name} • {APP_NAME}
      </title>
      <meta name='description' content={category.description} />
      <meta name='keywords' content={category.keywords} />
      <meta name='robots' content='all' />
      <meta
        property='og:site_name'
        content={`${category.name} • ${site_name}`}
      />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={`${category.name} • ${APP_NAME}`} />
      <meta property='og:image' content={image} />
      <meta property='og:description' content={category.description} />
      <link
        rel='canonical'
        href={`${window.location.origin}/${Categories.path}/${category_id}`}
      />
    </Helmet>
  ) : (
    <MainHelmet />
  )
}

export default function AppSEO({ children }) {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='products' element={<CategoriesHelmet />} />
          <Route path='categories/:id' element={<CategoriesHelmet />} />
          <Route path='/' element={<MainHelmet />} />
          <Route path='basket' element={<BasketHelmet />} />
          <Route path='products/:id' element={<ProductHelmet />} />
          <Route path='basket/:id' element={<ProductHelmet />} />
          <Route path='favorites/:id' element={<ProductHelmet />} />
          <Route path='categories/product/:id' element={<ProductHelmet />} />
          <Route path='favorites' element={<FavoriteHelmet />} />
          <Route path='profile' element={<ProfileHelmet />} />
          <Route path='contacts' element={<ContactsHelmet />} />
          <Route path='partnership' element={<PartnershipHelmet />} />
          <Route path='delivery' element={<DeliveryHelmet />} />
          <Route path='exchange' element={<ExchangeHelmet />} />
          <Route path='guarantee' element={<GuaranteeHelmet />} />
          <Route path='questions' element={<QuestionsHelmet />} />
          <Route path='privacy-policy' element={<PrivacyPolicyHelmet />} />
          <Route path='terms-of-use' element={<TermsOfUseHelmet />} />
          <Route path='*' element={<MainHelmet />} />
        </Routes>
      </BrowserRouter>
      {children}
    </>
  )
}
