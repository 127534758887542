import React from 'react'
import AppSuspenseFallback from './AppSuspenseFallback'
import AppSEO from './seo'

const AppProviders = React.lazy(() => import('./AppProviders'))
const ErrorBoundary = React.lazy(() => import('./ErrorBoundary'))
const AppContent = React.lazy(() => import('./AppContent'))
const AppError = React.lazy(() => import('./AppError'))

export default function App() {
  return (
    <AppSEO>
      <React.Suspense fallback={<AppSuspenseFallback />}>
        <AppProviders>
          <ErrorBoundary FallbackComponent={AppError}>
            <AppContent />
          </ErrorBoundary>
        </AppProviders>
      </React.Suspense>
    </AppSEO>
  )
}
