import { useMemo } from 'react'

export default function useRootNavigation(navigation) {
  return useMemo(() => {
    let rootNavigation = navigation
    let parentNavigation = rootNavigation.getParent()
    while (parentNavigation) {
      rootNavigation = parentNavigation
      parentNavigation = rootNavigation.getParent()
    }
    return rootNavigation
  }, [navigation])
}
