import { requester, SERVER_URL } from '@trendy/common'
import { APP_NAME } from '@trendy/common/src/settings'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

export default function ProductHelmet() {
  const [product_meta, set_product_meta] = useState({})
  const params = useParams()

  useEffect(() => {
    requester
      .get(`${SERVER_URL}/seo/products-${params.id}.json`)
      .then(res => set_product_meta(res))
  }, [params])

  const image = `${SERVER_URL}${product_meta.image}`

  return (
    <Helmet>
      <title>{product_meta.name}</title>
      <meta name='description' content={product_meta.description} />
      <meta name='keywords' content={product_meta.keywords} />
      <meta name='robots' content='all' />
      <meta
        property='og:site_name'
        content={`Маркетплейс детских товаров ${APP_NAME} в Бишкеке`}
      />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={product_meta.name} />
      <meta property='og:image' content={image} />
      <meta property='og:description' content={product_meta.description} />
      <link
        rel='canonical'
        href={window.location.origin + window.location.pathname}
      />
    </Helmet>
  )
}
