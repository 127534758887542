import { useEffect, useState } from 'react'
import { Keyboard, Platform } from 'react-native'

export function useIsKeyboardShown() {
  const [isKeyboardShown, setIsKeyboardShown] = useState(false)

  useEffect(() => {
    const handleKeyboardShow = () => setIsKeyboardShown(true)
    const handleKeyboardHide = () => setIsKeyboardShown(false)

    const subscriptions = []
    if (Platform.OS === 'ios') {
      subscriptions.push(
        Keyboard.addListener('keyboardWillShow', handleKeyboardShow),
        Keyboard.addListener('keyboardWillHide', handleKeyboardHide),
      )
    } else {
      subscriptions.push(
        Keyboard.addListener('keyboardDidShow', handleKeyboardShow),
        Keyboard.addListener('keyboardDidHide', handleKeyboardHide),
      )
    }

    return () => {
      subscriptions.forEach(s => s.remove())
    }
  }, [])

  return isKeyboardShown
}
