import { APP_NAME } from '@trendy/common/src/settings'
import { Helmet } from 'react-helmet'
import { site_name, image } from './index'

const description =
  'Заказ и доставка детская мебели, кровати, коляски, манежи и люльки. ✔ ️Детское постельное белье для кроваток купить. ❤️'
const keywords = [
  'мама и детки корзина',
  'мама и детки заказ',
  'доставка детских товаров',
  'доставка детских товаров Бишкек',
  'заказ детские товары',
  'заказ детские товары кыргызстан',
  'доставка одежды для детей',
  'поднузники заказ Бишкек',
].join(', ')

const title = `Корзина детских товаров - маркетплейс ${APP_NAME}`

export default function BasketHelmet() {
  return (
    <Helmet>
      <title>Корзина - {APP_NAME}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='robots' content='all' />
      <meta property='og:site_name' content={site_name} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:image' content={image} />
      <meta property='og:description' content={description} />
      <link
        rel='canonical'
        href={window.location.origin + window.location.pathname}
      />
    </Helmet>
  )
}
