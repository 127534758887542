import { useEffect, useState } from 'react'

const recaptcha = () => {
  // eslint-disable-next-line no-undef
  return grecaptcha.enterprise
}

export function useRecaptcha() {
  const [ready, setReady] = useState(false)
  const [verifier, setVerifier] = useState()

  useEffect(() => {
    let active = true
    recaptcha().ready(() => active && setReady(true))
    return () => {
      active = false
    }
  }, [])

  useEffect(() => {
    if (!ready) return
    setVerifier(() => {
      return async () =>
        recaptcha().execute(process.env.REACT_APP_RECAPTCHA, {
          action: 'login',
        })
    })
  }, [ready])

  useEffect(() => {
    const element = document.querySelector('.grecaptcha-badge')
    element?.classList.add('visible')
    return () => {
      element?.classList.remove('visible')
    }
  }, [])

  return verifier || null
}
