import { initializeApp } from 'firebase/app'
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage as onFirebaseMessage,
} from 'firebase/messaging'

import config from './firebase.config.json'

export const firebaseApp = initializeApp(config)

export async function isMessagingSupported() {
  return await isSupported()
}

export async function requestMessagingPermission() {
  return (await isSupported()) ? 1 : 0
}

export async function getMessagingToken() {
  try {
    return await getToken(getMessaging(firebaseApp))
  } catch {
    return ''
  }
}

export function onTokenRefresh(listener) {
  return () => undefined
}

export async function getInitialNotification() {
  return null
}

export function onMessage(listener) {
  return onFirebaseMessage(getMessaging(firebaseApp), listener)
}

export function onNotificationOpenedApp(listener) {
  return () => undefined
}
