export const DATA_TYPE_COlOR = 1
export const DATA_TYPE_SIZE = 2
export const DATA_TYPE_STRING = 3
export const DATA_TYPE_RANGE = 4
export const DATA_TYPE_DATE = 5
export const DATA_TYPE_DECIMAL = 6
export const DATA_TYPE_DICTIONARY = 7
export const DATA_TYPE_TEXTAREA = 8
export const DATA_TYPE_SELECT = 9

export const DATA_TYPES = [
  DATA_TYPE_COlOR,
  DATA_TYPE_SIZE,
  DATA_TYPE_STRING,
  DATA_TYPE_RANGE,
  DATA_TYPE_DATE,
  DATA_TYPE_DECIMAL,
  DATA_TYPE_DICTIONARY,
  DATA_TYPE_TEXTAREA,
  DATA_TYPE_SELECT,
]

export const DATA_TYPES_LABEL = {
  [DATA_TYPE_COlOR]: 'Цвет',
  [DATA_TYPE_SIZE]: 'Размер',
  [DATA_TYPE_STRING]: 'Текст',
  [DATA_TYPE_RANGE]: 'Интервал',
  [DATA_TYPE_DATE]: 'Дата',
  [DATA_TYPE_DECIMAL]: 'Число',
  [DATA_TYPE_DICTIONARY]: 'Справочник',
  [DATA_TYPE_TEXTAREA]: 'Длинный текст',
  [DATA_TYPE_SELECT]: 'Выборка',
}

export const BANNER_ACTION_URL = 'url'
export const BANNER_ACTION_CATEGORY = 'category'
export const BANNER_ACTION_PRODUCT = 'product'

export const NOTIFICATION_ACTION_URL = 'url'
export const NOTIFICATION_ACTION_CATEGORY = 'category'
export const NOTIFICATION_ACTION_PRODUCT = 'product'

export const BANNER_ACTIONS = [
  BANNER_ACTION_URL,
  BANNER_ACTION_CATEGORY,
  BANNER_ACTION_PRODUCT,
]

export const BANNER_ACTIONS_LABEL = {
  [BANNER_ACTION_URL]: 'Ссылка',
  [BANNER_ACTION_CATEGORY]: 'Категория',
  [BANNER_ACTION_PRODUCT]: 'Товар',
}

export const NOTIFICATION_ACTIONS = [
  NOTIFICATION_ACTION_URL,
  NOTIFICATION_ACTION_CATEGORY,
  NOTIFICATION_ACTION_PRODUCT,
]

export const NOTIFICATION_ACTIONS_LABEL = {
  [NOTIFICATION_ACTION_URL]: 'Ссылка',
  [NOTIFICATION_ACTION_CATEGORY]: 'Категория',
  [NOTIFICATION_ACTION_PRODUCT]: 'Товар',
}

export const CAMPAIGN_SIZE_1X1 = 1
export const CAMPAIGN_SIZE_2X1 = 2
export const CAMPAIGN_SIZE_3X1 = 3
export const CAMPAIGN_SIZE_4X1 = 4

export const CAMPAIGN_SIZES = [
  CAMPAIGN_SIZE_1X1,
  CAMPAIGN_SIZE_2X1,
  CAMPAIGN_SIZE_3X1,
  CAMPAIGN_SIZE_4X1,
]

export const CAMPAIGN_SIZES_LABEL = {
  [CAMPAIGN_SIZE_1X1]: 'Один к одному',
  [CAMPAIGN_SIZE_2X1]: 'Два к одному',
  [CAMPAIGN_SIZE_3X1]: 'Три к одному',
  [CAMPAIGN_SIZE_4X1]: 'Четыре к одному',
}

export const CATEGORY_ICON_DIAPERS = 'Diapers'
export const CATEGORY_ICON_SEAT = 'Seat'
export const CATEGORY_ICON_FEEDING = 'Feeding'
export const CATEGORY_ICON_FOOD = 'Food'
export const CATEGORY_ICON_TOYS = 'Toys'
export const CATEGORY_ICON_CARE = 'Care'
export const CATEGORY_ICON_PREGNANCY = 'Pregnancy'
export const CATEGORY_ICON_PLAYGROUND = 'Playground'
export const CATEGORY_ICON_WALKS = 'Walks'
export const CATEGORY_ICON_HOME = 'Home'
export const CATEGORY_ICON_CLOTHES = 'Clothes'
export const CATEGORY_ICON_DISCOUNTS = 'Discounts'

export const CATEGORY_ICONS = [
  CATEGORY_ICON_DIAPERS,
  CATEGORY_ICON_SEAT,
  CATEGORY_ICON_FEEDING,
  CATEGORY_ICON_FOOD,
  CATEGORY_ICON_TOYS,
  CATEGORY_ICON_CARE,
  CATEGORY_ICON_PREGNANCY,
  CATEGORY_ICON_PLAYGROUND,
  CATEGORY_ICON_WALKS,
  CATEGORY_ICON_HOME,
  CATEGORY_ICON_CLOTHES,
  CATEGORY_ICON_DISCOUNTS,
]

export const CATEGORY_ICONS_LABEL = {
  [CATEGORY_ICON_DIAPERS]: 'Подгузники',
  [CATEGORY_ICON_SEAT]: 'Автокресло',
  [CATEGORY_ICON_FEEDING]: 'Кормление',
  [CATEGORY_ICON_FOOD]: 'Питание',
  [CATEGORY_ICON_TOYS]: 'Игрушки',
  [CATEGORY_ICON_CARE]: 'Уход',
  [CATEGORY_ICON_PREGNANCY]: 'Беременность',
  [CATEGORY_ICON_PLAYGROUND]: 'Детская комната',
  [CATEGORY_ICON_WALKS]: 'Прогулки',
  [CATEGORY_ICON_HOME]: 'Для дома',
  [CATEGORY_ICON_CLOTHES]: 'Одежда',
  [CATEGORY_ICON_DISCOUNTS]: 'Акции',
}

export const VARIANT_TYPE_SIZE = 1
export const VARIANT_TYPE_COLOR = 2

export const VARIANT_TYPES = [VARIANT_TYPE_SIZE, VARIANT_TYPE_COLOR]

export const VARIANT_TYPES_LABEL = {
  [VARIANT_TYPE_SIZE]: 'Размер',
  [VARIANT_TYPE_COLOR]: 'Цвет',
}

export const ROLE_USER = 0
export const ROLE_DISTRIBUTOR = 3
export const ROLE_OPERATOR = 4
export const ROLE_MANAGER = 5
export const ROLE_ADMIN = 10

export const ROLES = [
  ROLE_USER,
  ROLE_DISTRIBUTOR,
  ROLE_OPERATOR,
  ROLE_MANAGER,
  ROLE_ADMIN,
]

export const ROLE_NAMES = {
  [ROLE_USER]: 'Пользователь',
  [ROLE_DISTRIBUTOR]: 'Дистрибьютор',
  [ROLE_OPERATOR]: 'Оператор',
  [ROLE_MANAGER]: 'Менеджер',
  [ROLE_ADMIN]: 'Админ',
}

export const PAYMENT_BY_CASH = 1
export const PAYMENT_BY_CARD = 2

export const PAYMENTS = [PAYMENT_BY_CASH, PAYMENT_BY_CARD]

export const PAYMENT_NAMES = {
  [PAYMENT_BY_CASH]: 'Оплатить наличными',
  [PAYMENT_BY_CARD]: 'Безналичная оплата',
}

export const ORDER_STATUS_NEW = 1
export const ORDER_STATUS_PREPAYMENT = 2
export const ORDER_STATUS_PAID = 3
export const ORDER_STATUS_PROCESSING = 4
export const ORDER_STATUS_ON_DELIVERY = 5
export const ORDER_STATUS_IN_TOWN = 6
export const ORDER_STATUS_DELIVERED = 7
export const ORDER_STATUS_CANCELLED = 8
export const ORDER_STATUS_RETURNED = 9
export const ORDER_STATUS_DELETED = 10

export const ORDER_STATUSES = [
  ORDER_STATUS_NEW,
  ORDER_STATUS_PREPAYMENT,
  ORDER_STATUS_PAID,
  ORDER_STATUS_PROCESSING,
  ORDER_STATUS_ON_DELIVERY,
  ORDER_STATUS_IN_TOWN,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_RETURNED,
  ORDER_STATUS_DELETED,
]

export const ORDER_STATUSES_LABEL = {
  [ORDER_STATUS_NEW]: 'Новый',
  [ORDER_STATUS_PREPAYMENT]: 'Ожидание оплаты',
  [ORDER_STATUS_PAID]: 'Оплачен',
  [ORDER_STATUS_PROCESSING]: 'Обрабатывается',
  [ORDER_STATUS_ON_DELIVERY]: 'Перевозка',
  [ORDER_STATUS_IN_TOWN]: 'Товар в городе',
  [ORDER_STATUS_DELIVERED]: 'Доставлен',
  [ORDER_STATUS_CANCELLED]: 'Отменен',
  [ORDER_STATUS_RETURNED]: 'Возврат',
  [ORDER_STATUS_DELETED]: 'Удален',
}

export const APP_DRAWER_WIDTH = 320

export const SORT_BESTSELLER = 'bestseller'
export const SORT_CHEAPEST = 'cheapest'
export const SORT_EXPENSIVE = 'expensive'

export const SEARCH_SORTING_VARIANTS = [
  SORT_BESTSELLER,
  SORT_CHEAPEST,
  SORT_EXPENSIVE,
]

export const SEARCH_PRICE_VARIANTS = [
  { value: { min: null, max: 99 }, label: '< 100' },
  { value: { min: 100, max: 499 }, label: '100 < 500' },
  { value: { min: 500, max: 999 }, label: '500 < 1000' },
  { value: { min: 1000, max: null }, label: '> 1000' },
]
