import { APP_NAME } from '@trendy/common/src/settings'
import { Helmet } from 'react-helmet'
import { site_name, image } from './index'

const description =
  'Политика Конфиденциальности. Политика Конфиденциальности сайта detki.kg'
const keywords = [
  'мама и детки политика конфиденциальности',
  'mama&detki политика конфиденциальности',
  'мама&детки политика конфиденциальности',
  'политика конфиденциальности сайта detki.kg',
].join(', ')

const title = `Политика Конфиденциальности - маркетплейс ${APP_NAME}`

export default function PrivacyPolicyHelmet() {
  return (
    <Helmet>
      <title>Политика конфиденциальности - {APP_NAME}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='robots' content='all' />
      <meta property='og:site_name' content={site_name} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:image' content={image} />
      <meta property='og:description' content={description} />
      <link
        rel='canonical'
        href={window.location.origin + window.location.pathname}
      />
    </Helmet>
  )
}
