import { isObservableArray } from 'mobx'
import { isWeb } from './platform'

export function defaultValueCheck(value) {
  return value != null && typeof value !== 'undefined'
}

export function getSafeValue(
  value,
  defaultValue = null,
  typeCheck = defaultValueCheck,
) {
  return typeCheck(value)
    ? value
    : typeof defaultValue !== 'undefined'
    ? defaultValue
    : value
}

export function arrayValueCheck(value) {
  return Array.isArray(value) || isObservableArray(value)
}

export function getArrayValue(
  value,
  defaultValue = [],
  typeCheck = arrayValueCheck,
) {
  return getSafeValue(value, defaultValue, typeCheck)
}

export const canHandleEvent = e =>
  !e.defaultPrevented && // onPress prevented default
  !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) && // ignore clicks with modifier keys
  (e.button == null || e.button === 0) && // ignore everything but left clicks
  (!isWeb || [undefined, null, '', 'self'].includes(e.currentTarget?.target)) // let browser handle "target=_blank" etc.
