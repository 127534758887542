import { APP_NAME } from '@trendy/common/src/settings'
import { Helmet } from 'react-helmet'
import { site_name, image } from './index'

const description =
  'Обмен и возврат, Как можно обменять или вернуть товар? Все ответы на ваши вопросы по ссылке'
const keywords = [
  'обмен и возврат детских товаров',
  'обмен и возврат детских патаний',
  'возврат детских товаров',
  'возврат детских одежд',
  'детские товары возврат Бишкек',
  'детские товары возврат Кыргызстан',
].join(', ')

const title = `Обмен и возврат - маркетплейс ${APP_NAME}`

export default function ExchangeHelmet() {
  return (
    <Helmet>
      <title>Обмен и возврат - {APP_NAME}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='robots' content='all' />
      <meta property='og:site_name' content={site_name} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:image' content={image} />
      <meta property='og:description' content={description} />
      <link
        rel='canonical'
        href={window.location.origin + window.location.pathname}
      />
    </Helmet>
  )
}
