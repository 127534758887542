import { APP_NAME } from '@trendy/common/src/settings'
import { Helmet } from 'react-helmet'
import { site_name, image } from './index'

const description =
  'Избранные детские товары: мебель, кровати, коляски, манежи, люльки, постельное белье для кроваток и игрушки. ✔️'
const keywords = [
  'избранные детские товары',
  'избранные детские товары Бишкек',
  'добавить детские товары в избранные',
  'избранные коляски',
  'избранные детское патание',
  'избранные детские одежды',
  'избранные детские товары кыргызстан',
].join(', ')

const title = `Избранные детские товары - маркетплейс ${APP_NAME}`

export default function FavoriteHelmet() {
  return (
    <Helmet>
      <title>Избранные - {APP_NAME}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='robots' content='all' />
      <meta property='og:site_name' content={site_name} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:image' content={image} />
      <meta property='og:description' content={description} />
      <link
        rel='canonical'
        href={window.location.origin + window.location.pathname}
      />
    </Helmet>
  )
}
