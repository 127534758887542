import 'react-app-polyfill/stable'
import './gtag'
import './icons'
import './yandex'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import App from '@trendy/components'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
