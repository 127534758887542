import axios from 'axios'
import { getAuthToken, signOut } from './auth'
import { API_URL } from './settings'

axios.defaults.baseURL = API_URL

axios.interceptors.request.use(async value => {
  const token = await getAuthToken()
  value.headers = token ? { token, ...value.headers } : value.headers
  return value
})

export const USER_NOT_AUTHORIZED = 101

axios.interceptors.response.use(async value => {
  if (value.status === 200) {
    const { result = 0 } = value.data || {}
    if (result === 0) {
      return value
    }
    if (result === USER_NOT_AUTHORIZED) {
      await signOut()
    }
  }
  throw value
})

// noinspection JSUnusedGlobalSymbols
export const requester = {
  async get(url, params, config) {
    return (await axios.get(url, { params, ...config })).data
  },
  async post(url, data, config) {
    return (await axios.post(url, data, config)).data
  },
  async put(url, data, config) {
    return (await axios.put(url, data, config)).data
  },
  async delete(url, params, config) {
    return (await axios.delete(url, { params, ...config })).data
  },
  async upload(path, file, config) {
    const form = new FormData()
    form.append('file', file)
    config = {
      ...config,
      headers: { ...config?.headers, 'Content-Type': 'multipart/form-data' },
    }
    return (await axios.post(`/upload/${path}`, form, config)).data.uri
  },
}

let request_interceptor_id = 0,
  response_interceptor_id = 0

export function setupInterceptors(store) {
  response_interceptor_id &&
    axios.interceptors.response.eject(response_interceptor_id)
  request_interceptor_id &&
    axios.interceptors.request.eject(request_interceptor_id)

  request_interceptor_id = axios.interceptors.request.use(value => {
    // noinspection JSUnresolvedVariable
    if (!value.silent) store.setBusy(true)
    return value
  })

  response_interceptor_id = axios.interceptors.response.use(
    value => {
      // noinspection JSUnresolvedVariable
      if (!value.config?.silent && !value.silent) store.setBusy(false)
      return value
    },
    error => {
      if (!error.config?.silent && !error.silent) {
        store.setBusy(false)
        store.showMessage(
          error.data?.message || error.message || error.toString(),
        )
      }
      return Promise.reject(error)
    },
  )
}
