import { getInitialNotification, onNotificationOpenedApp } from '@trendy/common'
import { Linking } from 'react-native'

const Main = {
  path: '',
  screens: {
    Home: {
      path: '',
      screens: {
        Home: '',
        Products: 'products',
        Product: 'products/:id',
        Information: 'info',
        Notification: 'notification',
      },
    },
    Categories: {
      path: 'categories',
      screens: {
        Categories: { path: 'categories', exact: true },
        Products: ':category',
        Product: 'product/:id',
      },
    },
    Basket: {
      path: 'basket',
      screens: {
        Basket: { path: 'basket', exact: true },
        BasketTemp: { path: 'basket-anon', exact: true },
        Orders: { path: 'orders', exact: true },
        Order: { path: 'order/:id', exact: true },
        Product: { path: ':id' },
      },
    },
    Favorites: {
      path: 'favorites',
      screens: {
        Favorites: { path: 'favorites', exact: true },
        Product: ':id',
      },
    },
    Profile: {
      path: 'profile',
    },
  },
}

const Admin = {
  path: 'admin',
  screens: {
    AdminMain: {
      path: 'admin',
      exact: true,
      screens: {
        Home: { path: 'admin', exact: true },
        Basket: 'basket',
        Profile: 'profile',
        Orders: 'orders',
        Order: 'order/:id',
        Categories: 'categories',
        Category: 'categories/:id',
        DataTypes: 'data-types',
        DataType: 'data-type/:id',
        Properties: 'properties',
        Property: 'properties/:id',
        Products: 'products',
        Product: 'products/:id',
        DisplayTopProducts: 'products/display/top',
        DisplayTopProduct: 'products/display/top/:id',
        DisplayNewProducts: 'products/display/new',
        DisplayNewProduct: 'products/display/new/:id',
        DisplayDiscountProducts: 'products/display/discount',
        DisplayDiscountProduct: 'products/display/discount/:id',
        Stores: 'stores',
        Store: 'stores/:id',
        Warehouses: 'warehouses',
        Warehouse: 'warehouses/:id',
        Storages: 'storages',
        Storage: 'storage/:id',
        Brands: 'brands',
        Brand: 'brands/:id',
        Users: 'users',
        User: 'users/:id',
        Components: 'components',
        Buttons: 'components/buttons',
        Texts: 'components/texts',
        Banners: 'banners',
        Banner: 'banners/:id',
        Campaigns: 'campaigns',
        Campaign: 'campaigns/:id',
        Backgrounds: 'backgrounds',
        Background: 'backgrounds/:id',
        Ads: 'ads',
        Ad: 'ad/:id',
        Notifications: 'notifications',
        Notification: 'notifications/:id',
        Settings: 'settings',
        Setting: 'setting/:id',
        NewSetting: 'settings/new',
      },
    },
  },
}

// noinspection HttpUrlsUsage,JSUnusedGlobalSymbols
const linking = {
  enabled: true,
  prefixes: [
    'http://localhost:3000',
    'https://trendy.apex.kg',
    'https://detki.kg',
  ],
  config: {
    screens: {
      Main,
      Admin,
      Login: { path: 'login' },
      PaymentSuccess: { path: 'payment/success' },
      PaymentFail: { path: 'payment/fail' },
      PaymentPaybox: { path: 'payment/paybox/:id' },
      Payment: { path: 'payment/:id' },
      Partnership: { path: 'partnership' },
      Feedback: { path: 'feedback' },
      Contacts: { path: 'contacts' },
      Delivery: { path: 'delivery' },
      Exchange: { path: 'exchange' },
      Guarantee: { path: 'guarantee' },
      PrivacyPolicy: { path: 'privacy-policy' },
      Questions: { path: 'questions' },
      TermsOfUse: { path: 'terms-of-use' },
    },
  },

  async getInitialURL() {
    let url = ''
    try {
      const notification = await getInitialNotification()
      url = notification?.data?.url || ''
    } catch {}
    url = url || (await Linking.getInitialURL()) || ''
    if (url.startsWith('/')) {
      url = linking.prefixes[0] + url
    }
    return url
  },

  subscribe(listener) {
    const urlListener = Linking.addEventListener('url', ({ url }) =>
      listener(url),
    )
    const disposeOnNotificationOpenedApp = onNotificationOpenedApp(
      notification => {
        let url = notification?.data?.url || ''
        if (url.startsWith('/')) {
          url = linking.prefixes[0] + url
        }
        url && listener(url)
      },
    )
    return () => {
      disposeOnNotificationOpenedApp()
      urlListener.remove()
    }
  },
}
export default linking
