import AsyncStorage from '@react-native-async-storage/async-storage'
import axios from 'axios'
import { Platform } from 'react-native'
import { EventEmitter } from 'events'

import { API_URL } from './settings'

const api = axios.create({ baseURL: `${API_URL}/auth` })
api.interceptors.response.use(async value => {
  if (value.status === 200 && value.data.result === 0) {
    return value
  }
  const error = new Error()
  error.message = value.data?.message || value.statusText
  error.config = value.config
  error.code = 'unknown'
  error.request = value.request
  error.response = value
  error.isAxiosError = true
  throw error
})

class AuthEventEmitter extends EventEmitter {}

const AUTH_CHANGED_EVENT = 'auth-changed'

const authEventEmitter = new AuthEventEmitter({})

const AUTH_STORAGE_KEY = 'auth-key'

async function getAuth() {
  try {
    const { token = null, user = null } = JSON.parse(
      (await AsyncStorage.getItem(AUTH_STORAGE_KEY)) || '{}',
    )
    return token && user ? { token, user } : { token: null, user: null }
  } catch {
    return { token: null, user: null }
  }
}

export async function getAuthToken() {
  const { token } = await getAuth()
  return token
}

export async function getAuthUser() {
  const { user } = await getAuth()
  return user
}

export async function setAuth({ token, user }) {
  try {
    await AsyncStorage.setItem(
      AUTH_STORAGE_KEY,
      JSON.stringify({ token, user }),
    )
  } catch {
  } finally {
    authEventEmitter.emit(AUTH_CHANGED_EVENT, user)
  }
}

export async function signIn(phoneNumber, verifier) {
  const recaptchaToken = await verifier()
  const { data } = await api.post(`/${phoneNumber}/${Platform.OS}`, {
    token: recaptchaToken,
  })
  return {
    async confirm(verificationCode = '') {
      const {
        data: { token, item: user },
      } = await api.post(`/${phoneNumber}/confirm`, {
        ...data,
        code: verificationCode,
      })
      await setAuth({ token, user })
      return user
    },
  }
}

export async function signOut() {
  try {
    await AsyncStorage.removeItem(AUTH_STORAGE_KEY)
  } catch {
  } finally {
    authEventEmitter.emit(AUTH_CHANGED_EVENT, null)
  }
}

export function onAuthChanged(listener) {
  authEventEmitter.on(AUTH_CHANGED_EVENT, listener)
  return () => authEventEmitter.off(AUTH_CHANGED_EVENT, listener)
}
