import { APP_NAME } from '@trendy/common/src/settings'
import { Helmet } from 'react-helmet'
import { site_name, image } from './index'

const description =
  'Детская мебель, кровати, коляски, манежи и люльки. ✔ ️Детское постельное белье для кроваток. ✔ ️Одежда для выписки из роддома, конверты и комплекты для новорожденных, для кормления ребенка, для купания ребенка, игрушки ❤️'
const keywords = [
  'детские товары',
  'детские товары Бишкек',
  'купить детские товары',
  'коляски',
  'детское патание',
  'выгодные цены',
  'детские одежды',
  'детские товары кыргызстан',
].join(', ')

const title = `Детские товары - маркетплейс ${APP_NAME}`

export default function MainHelmet() {
  return (
    <Helmet>
      <title>{APP_NAME}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='robots' content='all' />
      <meta property='og:site_name' content={site_name} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:image' content={image} />
      <meta property='og:description' content={description} />
      <link rel='canonical' href={window.location.origin} />
    </Helmet>
  )
}
