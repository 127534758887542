function yandex() {
  window.ym =
    window.ym ||
    function () {
      ;(window.ym.a = window.ym.a || []).push(arguments)
    }
  window.ym.l = 1 * new Date()

  for (let i = 0; i < document.scripts.length; i++) {
    if (document.scripts[i].src === 'https://mc.yandex.ru/metrika/tag.js') {
      return
    }
  }

  const script = document.createElement('script')
  script.async = true
  script.src = 'https://mc.yandex.ru/metrika/tag.js'
  const _script = document.getElementsByTagName('script')[0]
  _script.parentNode.insertBefore(script, _script)

  window.ym(94346943, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  })
}

!/googlebot/i.test(window.navigator.userAgent) &&
  process.env.NODE_ENV === 'production' &&
  yandex()
