import { APP_NAME } from '@trendy/common/src/settings'
import { Helmet } from 'react-helmet'
import { site_name, image } from './index'

const description =
  'Гарантия товаров. Мы заботимся о том, чтобы вы получили радость от покупки и исключительно проверенную продукцию для своего ребенка'
const keywords = [
  'гарантие детских товаров',
  'гарантие детских патаний',
  'гарантие детских одежды',
  'детские товары гарантия Бишкек',
  'детские товары гарантия Кыргызстан',
].join(', ')

const title = `Гарантия - маркетплейс ${APP_NAME}`

export default function GuaranteeHelmet() {
  return (
    <Helmet>
      <title>Гарантия - {APP_NAME}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='robots' content='all' />
      <meta property='og:site_name' content={site_name} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:image' content={image} />
      <meta property='og:description' content={description} />
      <link
        rel='canonical'
        href={window.location.origin + window.location.pathname}
      />
    </Helmet>
  )
}
